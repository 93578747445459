import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"

export default function carreiras() {
  return (
    <Layout color="white" breadcrumbs={["Trabalhe Conosco"]} item="1">
        <section class="banner_area">
        <div class="banner_inner d-flex align-items-center">
            <div class="overlay"></div>
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                <div class="banner_content text-center">
                    <h2>Trabalhe Conosco</h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section class="course_details_area section_gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 course_details_left">
                        <p>
                            Envie seu CV através de <a href="mailto:carreiras@vital.net.br">carreiras@vital.net.br</a>.
                        </p>
	                	</div>
                </div>
            </div>
        </section>
    </Layout>
  );
}